import * as React from "react"; 
import { graphql, Link } from "gatsby";
import Layout from "../components/layout.js";

function mapDatatoLinks(data)
{
    return data.allDirectory.nodes.map((node, index) => {
        const { name } = node;
        return (
            <li key={index}>
                <Link to={name}> {name} </Link>
            </li>
        );
    });
}

export default function Repo({ pageContext, data })
{
    const links = mapDatatoLinks(data);
    return (
        <Layout>
            <div>
                <h1> Kata level stuff idk </h1>
                <ul>
                    { links }
                </ul>
            </div>
        </Layout>
    );
}

export const query = graphql`
query($relativeDirectory : String!){
  allDirectory(filter: {relativeDirectory: {eq: $relativeDirectory}}) {
    nodes {
      name
      relativeDirectory
      relativePath
    }
  }
}
`;
